import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Invitation Only</h1>
        <p>
          本系统采用邀请制进行访问，首次访问请使用您顾问提供的专用链接。
        </p>
        <a
          className="App-link"
          href="https://www.uepath.com.au/service/contact.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </header>
    </div>
  );
}

export default App;
